@import "null.scss";

$c-accent: #4d66f3;
$c-accent-hover: #293ea8;
$c-accent-opacity: rgba(77, 102, 243, 0.1);
$c-accent-background: #f9fbff;
$c-neutral-1: #303030;
$c-neutral-2: #828282;
$c-neutral-3: #cfd8dc;

$br-desktop: 991px;
$br-tablet: 768px;
$br-tabs: 660px;
$br-tabs-center: 620px;
$br-mobile: 503px;

%ts-body {
  font-size: 16px;
  font-weight: 400;
  //line-height: 1.5em;
}

%ts-description {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5em;
}

%ts-info {
  font-size: 12.8px;
  line-heigt: 1.5em;
}

%ts-button {
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
}

%ts-select {
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  font-weight: 700;
}

%ts-logo {
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.01em;
  line-height: 22px;
  font-family: "Montserrat", sans-serif;
}

%ts-h3-title {
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
}

%ts-h2-title {
  font-size: 32px;
  font-weight: 700;
  line-height: 39px;
  font-family: "Montserrat", sans-serif;
}

%ts-h2-title-small {
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  font-family: "Montserrat", sans-serif;
}

%ts-large-text {
  font-size: 32px;
  line-height: 1.5em;
}

%ts-large-text-small {
  font-size: 22px;
  line-height: 1.5em;
}

.mobile-only {
  @media (min-width: $br-desktop + 1) {
    display: none !important;
  }
}

.desktop-only {
  @media (max-width: $br-desktop) {
    display: none !important;
  }
}

body {
  @extend %ts-body;
  color: $c-neutral-1;
  font-family: "Lato", sans-serif;
  -webkit-font-smoothing: antialiased;
}

.wrapper {
  display: flex;
}

.js-lock {
  overflow: hidden;
}

.js-hidden {
  visibility: hidden;
}

.main {
  flex-grow: 1;
  margin-left: 300px;

  &__tabs {
    padding: 40px 0;
  }

  &__tabs-title {
    text-align: center;
    margin-bottom: 40px;
  }

  &__result {
    padding: 32px 64px 64px;
    background-color: $c-accent-background;
    overflow: hidden;
    border-bottom: 1px solid #e8ebed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 40px;
    @media (max-width: $br-desktop) {
      padding: 48px;
      border-top: 1px solid #e8ebed;
    }
    @media (max-width: $br-mobile) {
      padding: 32px 16px;
      // padding-left: 16px;
      // padding-right: 16px;
    }
  }

  &__type {
    padding-top: 48px;
    padding-bottom: 40px;
    padding-left: 24px;
    padding-right: 24px;
    text-align: center;
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    align-items: center;
    //max-width: 400px;
    margin: 0 auto;
    @media (max-width: $br-mobile) {
      max-width: none;
      padding-left: 16px;
      padding-right: 16px;
    }
    @media (max-width: $br-desktop) {
      padding-bottom: 0;
      padding-top: 32px;
    }
  }

  &__customize {
    padding: 48px 64px 40px;
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 40px;
    @media (max-width: $br-mobile) {
      padding: 32px 16px 32px;
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  &__customize-title {
    display: none;
  }

  &__toggle-wrap {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    width: 100%;
    max-width: 300px;
  }

  &__picker {
    justify-content: center;
    max-width: 420px;
  }

  &__share {
    padding: 48px 48px 64px 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 32px;
    text-align: center;
    @media (max-width: $br-mobile) {
      padding-left: 16px;
      padding-right: 16px;
    }
    border-bottom: 1px solid #e8ebed;
  }

  &__share-links {
    display: flex;
    align-items: center;
    column-gap: 32px;
    user-select: none;
  }

  &__about {
    padding: 64px 24px;
    @media (max-width: $br-mobile) {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  &__testimonials {
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 64px;
    @media (max-width: $br-mobile) {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  &__footer {
  }

  &__title-container {
    position: relative;
  }

  &__result-title {
    &.js-is-loading {
      opacity: 0.5;
    }
  }

  &__text-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 16px;
    //@media (max-width: $br-desktop) {
    //  display: none;
    //}
  }

  &__subtitle {
    line-height: 1.5em;
    text-align: center;
    max-width: 500px;
  }

  &__switch {
    width: 100%;
    max-width: 400px;
    @media (max-width: $br-mobile) {
      max-width: none;
    }
  }

  max-width: 100%;
  @media (max-width: $br-desktop) {
    margin-left: 0;
  }
}

.button {
  padding: 12px 24px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
  box-shadow: 0px 2px 4px rgba(136, 144, 194, 0.2),
  0px 5px 15px rgba(37, 44, 97, 0.15);
  border-radius: 4px;
  user-select: none;

  &__text {
    @extend %ts-button;
  }

  &__icon {
    max-width: 12px;
  }

  &:hover {
    transform: translateY(-2px);
  }

  &_ghost {
    background: #ffffff;
    border: 2px solid $c-accent;
    color: $c-accent;

    &:hover {
      color: $c-accent-hover;
      border-color: $c-accent-hover;
    }
  }

  &_primary {
    background: $c-neutral-1;
    color: #fff;
    border: 2px solid $c-neutral-1;

    &:hover {
      background-color: #000;
      border-color: #000;
    }
  }

  &_ph {
    background-color: #da552f;
    border-color: #da552f;
    min-width: 247px;

    &:hover {
      background-color: #ff4400;
      border-color: #ff4400;
      box-shadow: 0px 2px 4px rgba(255, 68, 0, 0.16),
      0px 6px 12px rgba(255, 68, 0, 0.25);
      transform: translateY(-3px);
    }
  }

  &_download-float {
    //@media (max-width: $br-desktop) {
    position: fixed;
    z-index: 50;
    right: 24px;
    bottom: 24px;
    border-radius: 999px;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.14),
    0px 1px 18px rgba(0, 0, 0, 0.12), 0px 3px 5px rgba(0, 0, 0, 0.2);
    //}
    @media (max-width: $br-mobile) {
      right: 16px;
      bottom: 16px;
    }
    @media (min-width: $br-desktop + 1) {
      display: none;
    }
  }

  &_download-static {
    width: 100%;
  }

  transition: color 0.2s ease, background-color 0.2s ease,
  border-color 0.2s ease, box-shadow 0.2s ease, transform 0.2s ease;
}

.link {
  //text-decoration: underline;
  color: $c-accent;

  &:hover {
    opacity: 0.7;
  }

  transition: opacity 0.15s ease;
}

.container {
  max-width: 1280px;
  margin: 0 auto;
}

.navigation {
  border-bottom: 1px solid #e8ebed;

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 22px 24px;
    @media (max-width: $br-mobile) {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  &__description {
    color: $c-neutral-2;
    @extend %ts-description;
  }

  &__mobile-menu {
    display: none;

    &.js-visible {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 24px;
    }

    p {
      //margin-bottom: 8px;
      &:first-child {
        animation-name: fadein;
        animation-delay: 0.1s;
        animation-duration: 0.3s;
        animation-fill-mode: backwards;
        margin-bottom: 8px;
      }

      &:last-child {
        animation-name: fadein;
        animation-delay: 0.15s;
        animation-duration: 0.3s;
        animation-fill-mode: backwards;
      }
    }

    padding: 100px 16px 0;
    text-align: center;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 100;
    top: 0;
    left: 0;
    background-color: #fff;

    .navigation__description {
      max-width: 300px;
    }
  }

  &__mobile-menu-button {
    animation-name: fadein;
    animation-delay: 0.2s;
    animation-duration: 0.6s;
    animation-fill-mode: backwards;
  }

  @media (max-width: $br-desktop) {
    border: none;
  }
}

.switch {
  display: flex;
  align-items: center;
  justify-content: center;

  &__button {
    @extend %ts-select;
    padding: 6px 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 36px;
    color: $c-neutral-1;
    background-color: #fff;
    cursor: pointer;
    user-select: none;
    width: 50%;
    //border: 2px solid;
    //border-color: $c-neutral-3;
    box-shadow: inset 0px 0px 0px 1px $c-neutral-3;

    &:first-child {
      border-radius: 4px 0 0 4px;
      //border-right: 0;
      &.switch__button_selected {
        box-shadow: inset 0px 0px 0px 2px $c-accent;
        //border-right: 2px solid;
      }
    }

    &:last-child {
      border-radius: 0 4px 4px 0;
      //border-left: 0;
      &.switch__button_selected {
        //box-shadow: inset 2px 0px 0px $c-accent;
        //border-left: 2px solid;
        box-shadow: inset 0px 0px 0px 2px $c-accent;
      }
    }

    &_selected {
      color: $c-accent;
      border-color: $c-accent;
    }

    &:hover {
      color: $c-accent;
    }

    transition: color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
  }
}

.sidebar {
  background-color: #fff;
  border-right: 1px solid #e8ebed;
  position: fixed;
  width: 300px;
  height: 100%;
  overflow: auto;

  &__container {
  }

  &__description {
    color: $c-neutral-2;
    @extend %ts-description;

    p:first-child {
      //margin-bottom: 8px;
    }
  }

  &__logo-box {
    padding: 28px 24px 16px;
    //user-select: none;
    z-index: 110;
    line-height: 0;
    @media (max-width: $br-desktop) {
      padding: 0;
    }
  }

  &__logo-link {
    display: inline-flex;
    align-items: center;
    column-gap: 14px;

    &:hover {
      opacity: 0.7;
    }

    transition: opacity 0.2s ease;

    &.sidebar-only {
      margin-bottom: 12px;
    }
  }

  &__name {
    @extend %ts-logo;
    color: $c-neutral-1;
  }

  &__type-box {
    //margin-bottom: 64px;
  }

  &__title {
    margin-bottom: 24px;
  }

  &__logo {
  }

  &__options-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 64px;
    padding: 28px 0 188px;
    //max-width: 300px;
  }

  &__appearance {
  }

  &__toggle-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }

  &__share-links {
    display: flex;
    align-items: center;
    column-gap: 24px;
    user-select: none;
  }

  &__link {
  }

  &__type-box,
  &__color-picker,
  &__appearance,
  &__share {
    padding-left: 24px;
    padding-right: 24px;
  }

  &__category-box {
    .h3-title {
      padding: 0 24px;
    }
  }

  @media (max-width: $br-desktop) {
    display: none;
  }
}

.share-link {
  img {
    width: 28px;
    @media (max-width: $br-desktop) {
      width: 32px;
    }
  }

  &:hover {
    opacity: 0.8;
    transform: translateY(-2px);
  }

  transition: transform 0.15s ease, opacity 0.15s ease;
}

.h2-title {
  @extend %ts-h2-title;
  @media (max-width: $br-mobile) {
    font-size: 24px;
    //line-height: 1.3em;
  }
  line-height: 1.3em;
  text-align: center;
  color: $c-neutral-1;
}

.h3-title {
  @extend %ts-h3-title;
  color: $c-neutral-1;
}

.picker {
  display: flex;
  column-gap: 16px;
  row-gap: 24px;
  flex-wrap: wrap;

  &__icon {
    opacity: 0;
    width: 20px;
    transition: opacity 0.05s ease;
    user-select: none;
    @media (max-width: $br-desktop) {
      width: 32px;
    }
  }

  &__button {
    width: 32px;
    height: 32px;
    border-radius: 7px;
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.15);
    background-color: #4d66f3;

    &_active {
      transform: scale(0.8);

      .picker__icon {
        opacity: 1;
      }
    }

    @media (max-width: $br-desktop) {
      width: 56px;
      height: 56px;
    }
    transition: transform 0.05s ease;
  }

  @media (max-width: $br-desktop) {
    column-gap: 24px;
  }
}

.toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__button {
    width: 34px;
    border-radius: 56px;
    background-color: #cfd8dc;
    padding: 3px 3px;

    &:after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 14px;
      height: 14px;
      background-color: white;
      transition: transform 0.07s ease;
    }
  }

  &__button_active {
    background-color: $c-accent;

    &:after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 14px;
      height: 14px;
      background-color: white;
      transform: translateX(100%);
      transition: transform 0.07s ease;
    }

    transition: background-color 0.07s ease;
  }
}

.tabs {
  &__container {
    display: flex;
    column-gap: 8px;
    row-gap: 24px;
    margin: 0 auto;

    &_main {
      padding: 0 24px;
      flex-wrap: nowrap;
      overflow: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }

      justify-content: center;
      @media (max-width: $br-tabs) {
        column-gap: 0;
      }
      @media (max-width: $br-tabs-center) {
        justify-content: flex-start;
      }
      @media (max-width: $br-mobile) {
        padding: 0 16px;
      }
    }

    &_sidebar {
      padding: 0 24px;
      justify-content: flex-start;
      flex-wrap: wrap;
    }
  }

  &__tab {
    //width: 150px;
    //min-width: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 8px;
    padding: 12px 24px;
    cursor: pointer;
    background-color: #fff;
    border-radius: 10px;
    user-select: none;
    color: $c-neutral-1;

    &:hover {
      background-color: $c-accent-opacity;
    }

    &.tabs__tab_active {
      color: $c-accent;
      background-color: $c-accent-opacity;
    }

    @media (max-width: $br-tabs) {
      background-color: #f7f7f7;
      border-radius: 0;
      &:first-child {
        border-radius: 10px 0 0 10px;
      }
      &:last-child {
        border-radius: 0 10px 10px 0;
      }
    }
    transition: background-color 0.09s ease, color 0.09s ease;
  }

  &__icon {
    max-width: 24px;
  }

  &__text {
    @extend %ts-select;
    display: none;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.result-image {
  max-width: 100%;
  max-height: 524px;

  &.has-shadow {
    box-shadow: 0px 0px 2.2138051986694336px 0px #252c6104,
    0px 0px 5.32008171081543px 0px #252c6105,
    0px 0px 10.017241477966309px 0px #252c6106,
    0px 0px 17.869047164916992px 0px #252c6108,
    0px 0px 33.422088623046875px 0px #252c6109, 0px 0px 80px 0px #252c610d;
  }

  user-select: none;
}

// .no-shadow {
//  box-shadow: none;
// }

.burger {
  width: 24px;
  height: 18px;
  position: relative;
  cursor: pointer;
  z-index: 110;
  user-select: none;

  &:before,
  &:after {
    content: "";
  }

  span,
  &:before,
  &:after {
    position: absolute;
    height: 2px;
    width: 100%;
    background-color: $c-neutral-1;
    border-radius: 999px;
    transition: all 0.2s ease;
  }

  &:before {
    top: 0;
  }

  span {
    top: 8px;
  }

  &:after {
    bottom: 0;
  }

  // &:hover {
  //  opacity: 0.6;
  // }
  &.js-active span {
    transform: scaleX(0) translateX(-50%);
  }

  &.js-active:before {
    transform: translateY(-50%) rotate(45deg);
    top: 50%;
  }

  &.js-active:after {
    transform: translateY(-50%) rotate(-45deg);
    top: 50%;
  }

  transition: opacity 0.2s ease;
}

.about {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__textarea {
    display: flex;
    margin-bottom: 64px;
    justify-content: center;
    column-gap: 64px;
    row-gap: 32px;
    align-items: center;
    @media (max-width: $br-tablet) {
      flex-direction: column;
      text-align: center;
    }
  }

  &__large-text {
    @extend %ts-large-text;
    max-width: 435px;
    @media (max-width: $br-mobile) {
      font-size: 22px;
    }
  }

  &__small-text {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    max-width: 435px;
    line-height: 1.5em;
  }

  &__button {
    margin-bottom: 8px;
  }
}

.info {
  @extend %ts-info;
  color: $c-neutral-2;
}

.testimonials {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__item {
    position: relative;
    max-width: 300px;
  }

  &__icon {
    position: absolute;
    z-index: -10;
    top: -12px;
    left: -26px;
    user-select: none;
  }

  &__photo {
    max-width: 48px;
    user-select: none;
  }

  &__name {
    @extend %ts-h3-title;
    margin-bottom: 4px;
  }

  &__info {
    @extend %ts-description;
    color: $c-neutral-2;
  }

  &__author-box {
    display: flex;
    align-items: center;
    column-gap: 20px;
  }

  &__text {
    margin-bottom: 16px;
    line-height: 1.5em;
  }
}

.footer {
  &__container {
    max-width: 215px;
    border-top: 1px solid #e8ebed;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 28px 0;
    column-gap: 16px;
    @media (max-width: $br-mobile) {
      padding-bottom: 80px;
    }
  }

  &__link {
    user-select: none;
    line-height: 20px;

    &:hover {
      opacity: 0.65;
      transform: translateY(-2px);
    }

    transition: transform 0.15s ease, opacity 0.15s ease;
  }

  &__icon {
    width: 24px;
  }
}

.spinner {
  width: 64px;
  height: 64px;
  position: absolute;
  top: -12px;
  right: -64px;
  @media (max-width: $br-mobile) {
    width: 48px;
    height: 48px;
    top: -9px;
    right: -47px;
  }
  visibility: hidden;

  &.js-is-active {
    visibility: visible;
  }
}

.spinner svg {
  animation: rotate 0.7s linear infinite;
  height: 100%;
  width: 100%;
}

.spinner circle {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation: dash 1.5s ease-in-out infinite 0s,
  color 6s ease-in-out infinite -0.75s;
  stroke-linecap: round;
  fill: none;
  stroke-width: 4;
}

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
}

@keyframes color {
  100%,
  0% {
    stroke: #4285f4;
  }
  25% {
    stroke: #de3e35;
  }
  50% {
    stroke: #f7c223;
  }
  75% {
    stroke: #1da760;
  }
}

//FOR PH LAUNCH v

// .not-found {
//   &__container {
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//   }
//   &__message-top {
//     font-size: 64px;
//     font-weight: 700;
//     font-family: "montserrat";
//     line-height: 1em;
//   }
// }

// .navigation {
//   @media (min-width: $br-desktop + 1) {
//     display: none;
//   }
// }
// .button_ph {
//   display: none;
// }

// .sidebar {
//  &__description {
//    display: none;
//  }
//  &__logo-link {
//    margin-bottom: 0;
//  }
// }

#root .tooltip-custom {
  @extend %ts-description;
  font-weight: 400;
  //border-radius: 4px;
  padding: 4px 16px;
  transition: opacity 0.15s ease;
  background-color: $c-neutral-1;
  //position: relative;
  z-index: 9999;

  &:after {
    border-top-color: $c-neutral-1;
  }

  &.show {
    opacity: 0.9;
  }

  &_image {
    //box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.0534177),
    //0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0767396),
    //0px 22.3363px 17.869px rgba(0, 0, 0, 0.095),
    //0px 12.5216px 10.0172px rgba(0, 0, 0, 0.11326),
    //0px 6.6501px 5.32008px rgba(0, 0, 0, 0.136582),
    //0px 2.76726px 2.21381px rgba(0, 0, 0, 0.19);

    box-shadow: 0px 8px 28px rgba(0, 0, 0, 0.25), 0px 18px 88px rgba(0, 0, 0, 0.2);

    transition: opacity 0.15s ease;
    padding: 8px;
    min-width: 416px;
    text-align: center;
    //border-radius: 4px;

    &.show {
      opacity: 1 !important;
    }
  }

  &__picture {
    //border-radius: 4px;
  }

  @media (max-width: $br-desktop) {
    display: none;
  }

}

.category {
  &__wrapper {
    line-height: 16px;

    .h3-title {
      margin-bottom: 0;
      padding-right: 4px;
    }

    margin-bottom: 24px;
    display: flex;
    align-items: baseline;
  }

  &__subtitle {
    @extend %ts-description;
    color: $c-neutral-2;
    line-height: 1em;
  }
}

.download-wrapper {
  z-index: 100;
  width: 299px;
  display: flex;
  justify-content: center;
  padding: 16px 24px;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: white;
  border-top: 1px solid #e8ebed;
  box-shadow: 0 -22px 14px -16px rgb(255 255 255),
  0 -80px 24px -20px rgb(255 255 255 / 65%),
  0 -38px 14px -16px rgb(255 255 255 / 75%);
}

.hover-info {
  text-decoration: underline;
  cursor: help;
}





